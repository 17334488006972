import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Colors from "../../../../assets/styles"; 
export default function SuccessPage() {
  const location = useLocation();
  const navigate = useNavigate(); 

  const queryParams = new URLSearchParams(location.search);
  const transactionId = queryParams.get("transactionId");

  const handleBackToHome = () => {
    navigate("/home"); 
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: Colors.background || "#f4f4f4", 
      }}
    >
      <div
        style={{
          textAlign: "center",
          backgroundColor: Colors.card || "white",
          padding: "30px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", 
          maxWidth: "400px",
          width: "90%", 
        }}
      >
        {transactionId ? (
          <div>
            <h1 style={{ color: Colors.success || "#28a745" }}>Success</h1>
            <p style={{ fontSize: "18px", color: Colors.text || "#333" }}>
              Card Added Successfully
            </p>
          </div>
        ) : (
          <div>
            <h1 style={{ color: Colors.error || "#dc3545" }}>Failed</h1>
            <p style={{ fontSize: "18px", color: Colors.text || "#333" }}>
             Failed To Add Cardg.
            </p>
          </div>
        )}
        <button
          onClick={handleBackToHome}
          style={{
            marginTop: "20px",
            padding: "12px 24px",
            fontSize: "16px",
            backgroundColor: Colors.primary || "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background-color 0.3s",
          }}
         
        >
          Back to Home
        </button>
      </div>
    </div>
  );
}
