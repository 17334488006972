import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
  CircularProgress,
  Alert,
  TextField,
  Card,
  Divider,
  Dialog,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ForwardIcon from "@mui/icons-material/ArrowForward";
import { paymentApiCall } from "../../../../api/PaymentServices";
import {
  setIsWallet,
  setPaymentCardData,
} from "../../../../redux/slices/CartSlice";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Colors from "../../../../assets/styles";
import AddCardModals from "./AddCardModal";
import { ErrorToaster, SuccessToaster } from "../../../../components/Toaster";

const AddPaymentMethodModal = ({
  setClose,
  onClick,
  closeAllModal,
  fromWallet,
  addAmount,
  setAddPaymentMethodModal,
  setIsWalletEnabled,
  getWalletBalance,
  getWalletHistory,
}) => {
  const dispatch = useDispatch();
  const { control, reset, handleSubmit } = useForm();
  const [loader, setLoader] = useState(false);
  const [removeLoader, setRemoveLoader] = useState(false);
  const [removeCardModal, setRemoveCardModal] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [cards, setCards] = useState([]);
  const [addCardModal, setAddCardModal] = useState(false);
  const [activeScreen, setActiveScreen] = useState(0);
  const [cardID, setcardID] = useState(null);
  const [cardUrl, setCardUrl] = useState(null);
  const [addCardModalVisible, setAddCardModalVisible] = useState(false);

  const userData = useSelector((state) => state.userData.userData);
  const paymentCardData = useSelector(
    (state) => state.cartData.paymentCardData
  );
  console.log(paymentCardData);
  const userIpAddress = useSelector((state) => state.userData.userIpAddress);

  const removeCardHandler = async () => {
    setRemoveLoader(true);
    try {
      await paymentApiCall.deleteCard(cardID);
      getPaymentCardHandler();
      setRemoveCardModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setRemoveLoader(false);
    }
  };

  const getPaymentCardHandler = async (autoSelect) => {
    setLoader(true);
    try {
      const result = await paymentApiCall.getCards({ user_id: userData?.id });
      setCards(result);

      if (autoSelect) {
        if (result?.length <= 0) {
          dispatch(setPaymentCardData({}));
          setAddPaymentMethodModal(false);
        } else {
          dispatch(setPaymentCardData(result?.[0]));
          setAddPaymentMethodModal(false);
        }
      } else {
        if (result?.length <= 0) {
          dispatch(setPaymentCardData({}));
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const addCardHandler = async (paymentCardField) => {
    console.log(paymentCardField);

    if (paymentCardField) {
      const obj = {
        user_id: userData?.id,
        card_holder: paymentCardField.name,
        card_number: paymentCardField.number.replace(/\s+/g, ""), // Remove spaces
        expirydate: paymentCardField.expiry,
        cvv: paymentCardField.cvc,
        is_default: true,
        card_type: paymentCardField.type,
        userIpAddress: userIpAddress,
      };

      try {
        const result = await paymentApiCall.addCard(obj);

        if (result?.status === "1") {
          if (fromWallet) {
            setModalVisible(true);
            setAddCardModal(false);
            getPaymentCardHandler(false);
          } else {
            setAddCardModal(false);
            getPaymentCardHandler(true);
            setAddPaymentMethodModal(true);
            setActiveScreen(0);
          }
        } else {
          ErrorToaster(result?.message || "Something went wrong!");
          setAddCardModal(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
    } else {
      alert("Please fill the fields first!");
    }
  };
  const addCard = async () => {
    const obj = {
      user_id: userData?.id,
    };
  
    try {
      const result = await paymentApiCall.addCard(obj);
      console.log(result);
      if (result?.status === true) {
        window.location.href =result?.data?.["consumer-url"]
        // setCardUrl(result?.data?.["consumer-url"]);
        // setAddCardModalVisible(true);
      } else {
        ErrorToaster(result?.message || "Something went wrong!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const amountHandler = async (data) => {
    setLoader(true);
    const obj = {
      user_id: userData?.id,
      amount: data?.amount,
      card_id: paymentCardData?.id,
      currency: "KYD",
    };
    try {
      const result = await paymentApiCall.addToWallet(obj);
      if (result?.status_code === "0") {
        ErrorToaster(result?.status);
      } else {
        SuccessToaster(result?.status);
        closeAllModal();
        getWalletHistory();
        getWalletBalance();
      }
      reset();
      setModalVisible(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getPaymentCardHandler();
  }, []);

  return (
    <>
    {/* <Dialog
      open={addCardModalVisible}
      onClose={()=>setAddCardModalVisible(false)}
      maxWidth="md" 
      fullWidth
      PaperProps={{
        sx: {
          height: "80vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        <iframe
          src={cardUrl}
          title="Add Card"
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
          sandbox="allow-scripts allow-same-origin"
        />
      </Box>
      <Box sx={{ p: 2, textAlign: "right" }}>
        <Button onClick={()=>(false)} variant="contained" color="primary">
          Close
        </Button>
      </Box>
    </Dialog> */}

      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
              boxShadow: 3,
              position: "relative",
            }}
          >
            <IconButton
              onClick={() => {
                setModalVisible(false);
                reset();
              }}
              sx={{ position: "absolute", top: 16, right: 16 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" gutterBottom>
              Add money to wallet
            </Typography>
            <Controller
              name="amount"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Enter amount"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              )}
              rules={{ required: "Amount is required" }}
            />
            <Button
              variant="contained"
              sx={{
                background: Colors.black,
                ":hover": {
                  background: Colors.black,
                },
              }}
              fullWidth
              onClick={handleSubmit(amountHandler)}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={removeCardModal} onClose={() => setRemoveCardModal(false)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "18px", fontWeight: "600" }}
            >
              Are you sure you want to remove this card?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "40px",
                mt: 4,
              }}
            >
              <Button
                variant="contained"
                sx={{ background: Colors.black, px: 3.5 }}
                onClick={removeCardHandler}
                disabled={removeLoader}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                sx={{ color: "secondary" }}
                onClick={() => setRemoveCardModal(false)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {activeScreen === 0 && (
        <Box sx={{ p: 2 }}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "1rem 0",
              borderBottom: "1px solid #d3d3d3",
              paddingBottom: "16px",
              cursor: "pointer",
              p: 1.5,
            }}
            onClick={() => {
              setAddCardModal(true);
              setActiveScreen(1);
              addCard();
            }}
          >
            <Box style={{ width: "36px", height: "36px" }}>
              <CreditCardIcon style={{ width: "100%", height: "100%" }} />
            </Box>

            <Box style={{ flex: 1, padding: "0 16px" }}>
              <Typography variant="body1" fontWeight="bold">
                Credit or debit card
              </Typography>
            </Box>

            <IconButton style={{ padding: "6px" }}>
              <ArrowForwardIosIcon style={{ width: "24px", height: "24px" }} />
            </IconButton>
          </Card>

          {!fromWallet && (
            <Card
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "1rem 0",
                borderBottom: "1px solid #d3d3d3",
                paddingBottom: "16px",
                cursor: "pointer",
                p: 1.5,
              }}
              onClick={() => {
                dispatch(setIsWallet(true));
                closeAllModal();
                setIsWalletEnabled(true);
              }}
            >
              <Box style={{ width: "36px", height: "36px" }}>
                <WalletIcon style={{ width: "100%", height: "100%" }} />
              </Box>

              <Box style={{ flex: 1, padding: "0 16px" }}>
                <Typography variant="body1" fontWeight="bold">
                  Pay from Wallet
                </Typography>
              </Box>

              <IconButton style={{ padding: "6px" }}>
                <ArrowForwardIosIcon
                  style={{ width: "24px", height: "24px" }}
                />
              </IconButton>
            </Card>
          )}

          {loader ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Card
                sx={{
                  margin: "1rem 0",
                  borderBottom: "1px solid #d3d3d3",
                  paddingBottom: "16px",
                  cursor: "pointer",
                  p: 1.5,
                }}
                onClick={() => {
                  dispatch(setIsWallet(false));
                }}
              >
                {cards.map((card, index) => (
                  <>
                    <Box
                      onClick={() => {
                        dispatch(setPaymentCardData(card));
                        if (fromWallet) {
                          setModalVisible(true);
                        } else {
                          closeAllModal();
                        }
                      }}
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        my: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CreditCardIcon />
                        <Box sx={{ ml: 2 }}>
                          <Typography variant="body1">
                            {card.card_type}
                          </Typography>
                          <Typography variant="body2">
                            {card.last_four}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                          variant="text"
                          onClick={(event) => {
                            event.stopPropagation();
                            setcardID(card.id);
                            setRemoveCardModal(true);
                          }}
                        >
                          Remove
                        </Button>
                        {/* <IconButton 
              
                >
                  <ForwardIcon />
                </IconButton> */}
                      </Box>
                    </Box>
                    <Divider />
                  </>
                ))}
              </Card>
            </>
          )}
        </Box>
      )}
      {/*  //old method//
      {activeScreen == 1 && (
        <AddCardModals
          modalVisible={addCardModal}
          setClose={() => setAddCardModal(false)}
          setActiveScreen={setActiveScreen}
          addCard={addCardHandler}
          // loader={loader}
        />
      )} */}
    </>
  );
};

export default AddPaymentMethodModal;
