export const paymentEndpoints = {
  // addCard: '/customer/addCard',
  // getCard: '/customer/GetCards_new',
  // addToWallet: '/customer/Add2wallet_new',
  // tipPayment: '/customer/TipPayment_new',
  // deleteCard: '/customer/DeleteCard',
  // pay3d: '/customer/Pay3ds_new',
  // pay3d: '/customer/Payment3ds', // Latest with Payer auth but it is not working.
  
  pay3d: '/customer//PaymentV2',
  getCard: '/customer/GetCardsV2',
  addCard: '/customer/addCardCG',
  addToWallet: '/customer/Add2walletV2',
  tipPayment: '/customer/TipPaymentV2',
  deleteCard: '/customer/DeleteCardV2',
  };
  